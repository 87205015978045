<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="发送短信"
      width="1000px"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        @submit="handleSubmit"
      >
        <a-row :gutter="12">
          <a-col span="12">
            <a-row class="title">
              <a-col span="12">接收者:</a-col>
              <a-col span="12">
                <div class="phone-number-count">{{ phoneNumberCount }}个</div>
              </a-col>
            </a-row>

            <a-form-item
              :wrapper-col="{ span: 24 }"
              :validate-status="validateStatus"
              :help="errorMsg"
            >
              <a-textarea
                placeholder="请输入接收者电话号码，一行一个，最多输入500个"
                :rows="10"
                @blur="handlePhoneNumbers"
                v-decorator="['phone_number', {
                  rules: [
                    { required: true, message: '请输入接收者电话号码' },
                  ]
                }]"
                :style="{ color: validateStatus === 'error' ? 'red' : '' }"
              />
            </a-form-item>
          </a-col>

          <a-col span="12">
            <a-form-item label="短信模板">
              <a-select
                v-decorator="['template',{
                  rules: [
                    { required: true, message: '请选择短信模板' },
                  ]
                }]"
                allow-clear
                @change="changeTemplate"
              >
                <a-select-option
                  v-for="option in smsTemplateOptions"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="环节名称" v-if="templateSlug === 'link'">
              <a-input
                v-decorator="['link_name', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入环节名称'},
                    { max: 50, message: '最多50个字符' },
                  ]
                }]"
                @change="changeLinkName"
                autocomplete="off"
              />
            </a-form-item>
            <a-form-item label="时间" v-if="templateSlug === 'link' || templateSlug === 'meal'">
              <a-range-picker
                format="YYYY-MM-DD HH:mm"
                :show-time="{ format: 'HH:mm' }"
                style="width: 100%;"
                v-decorator="[
                  'time_at',
                  {
                    rules: [{ required: true, message: '请选择时间' }],
                  },
                ]"
                @change="clearDay"
                @ok="changeTimeAt"
              />
            </a-form-item>
            <a-form-item :label="destinationLabel" v-if="templateSlug === 'link' || templateSlug === 'meal'">
              <a-input
                style="width: 100%;"
                v-decorator="['destination', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入地点'},
                    { max: 50, message: '最多50个字符' },
                  ]
                }]"
                @change="changeDestination"
                autocomplete="off"
              />
            </a-form-item>
            <a-form-item label="距离节日天数" v-if="templateSlug === 'festival'">
              <a-input-number
                v-decorator="['days', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入距离节日天数'},
                  ]
                }]"
                :min="1"
                :max="999"
                @change="changeDays"
              />
            </a-form-item>
            <a-form-item label="节日名称" v-if="templateSlug === 'festival'">
              <a-input
                v-decorator="['festival', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入节日名称'},
                    { max: 50, message: '最多50个字符' },
                  ]
                }]"
                @change="changeFestival"
                autocomplete="off"
              />
            </a-form-item>
            <a-form-item label="内容">
              {{ content }}
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { findSmsTemplateOptions, sendSms } from '@/api/sms_record'
export default {
  name: 'SendSms',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'send_sms_record' }),
      submitting: false,
      phoneNumberString: '',
      validateStatus: 'success',
      errorMsg: '',
      phoneNumberCount: 0,
      smsTemplateOptions: [],
      templateSlug: '',
      templateContent: '',
      destinationLabel: '',
      linkName: '',
      beginAt: '',
      endAt: '',
      destination: '',
      days: 'A',
      festival: 'B'
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    content: function() {
      if (this.templateSlug === 'link') {
        let con = this.templateContent.replaceAll('{1}', this.linkName)
        con = con.replaceAll('{3}', this.beginAt)
        con = con.replaceAll('{4}', this.endAt)
        con = con.replaceAll('{2}', this.destination)
        return con
      } else if (this.templateSlug === 'meal') {
        let con = this.templateContent.replaceAll('{1}', this.destination)
        con = con.replaceAll('{2}', this.beginAt)
        con = con.replaceAll('{3}', this.endAt)
        return con
      } else if (this.templateSlug === 'festival') {
        let con = ''
        if (this.days !== '' && this.days !== null) {
          con = this.templateContent.replaceAll('{1}', this.days)
        } else {
          con = this.templateContent.replaceAll('{1}', 'A')
        }
        if (this.festival !== '' && this.festival !== null) {
          con = con.replaceAll('{2}', this.festival)
        } else {
          con = con.replaceAll('{2}', 'B')
        }
        return con
      }
    }
  },
  created() {
    this.fetchSmsTemplateOptions()
  },
  methods: {
    changeTimeAt(e) {
      this.beginAt = e[0].format('YYYY-MM-DD HH:mm')
      this.endAt = e[1].format('YYYY-MM-DD HH:mm')
    },
    clearDay() {
      this.beginAt = ''
      this.endAt = ''
    },
    changeLinkName(e) {
      this.linkName = e.target.value
    },
    changeDestination(e) {
      this.destination = e.target.value
    },
    changeDays(e) {
      this.days = e
    },
    changeFestival(e) {
      this.festival = e.target.value
    },
    fetchSmsTemplateOptions() {
      findSmsTemplateOptions().then((res) => {
        if (res.code === 0) {
          this.smsTemplateOptions = res.data
        }
      })
    },
    changeTemplate(e) {
      this.days = ''
      this.beginAt = ''
      this.endAt = ''
      this.destination = ''
      this.festival = ''
      this.linkName = ''
      this.form.setFieldsValue({
        link_name: '',
        festival: '',
        destination: '',
        days: '',
        time_at: ''
      })
      this.smsTemplateOptions.forEach((option) => {
        if (option.id === e) {
          this.templateSlug = option.slug
          this.templateContent = option.content
        }
      })
      if (this.templateSlug === 'link') {
        this.destinationLabel = '地点'
      }
      if (this.templateSlug === 'meal') {
        this.destinationLabel = '就餐地点'
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      if (!this.form.getFieldValue('phone_number')) {
        this.validateStatus = 'error'
        this.errorMsg = '请输入接收者电话号码'
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.errorMsg) {
            return
          }
          const data = {
            ...values,
            phone_number: this.phoneNumberString,
            content: this.content,
            begin_at: this.beginAt,
            end_at: this.endAt
          }
          sendSms(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    },

    handlePhoneNumbers(e) {
      const phoneNumberPatten = /^1[3-9]\d{9}$/
      const phoneNumbers = this.$lodash.split(this.$lodash.trim(e.target.value), /\r*\n/)
      const maxLines = 50000
      // 超过5万行不处理
      if (phoneNumbers.length > maxLines) {
        this.validateStatus = 'error'
        this.errorMsg = `手机号码数量不能超过${maxLines}`
        return
      }

      const validPhoneNumbers = []
      let errorText = ''
      for (const phoneNumber of phoneNumbers) {
        const value = this.$lodash.trim(phoneNumber)
        if (!value) {
          continue
        }

        // 包含冒号(有错误)
        if (value.indexOf(':') > 0) {
          errorText += `${value}\n`
          continue
        }

        if (phoneNumberPatten.test(value)) {
          validPhoneNumbers.push(value)
        } else {
          errorText += `${value}:手机号码格式不正确\n`
        }
      }

      // 处理错误
      const uniqValidPhoneNumbers = this.$lodash.uniq(validPhoneNumbers)
      if (errorText === '') {
        this.validateStatus = 'success'
        this.errorMsg = null
        this.phoneNumberString = uniqValidPhoneNumbers.join(',')
      } else {
        this.validateStatus = 'error'
        this.errorMsg = '手机号码包含错误，无法提交'
      }

      this.phoneNumberCount = uniqValidPhoneNumbers.length
      // 拼接 textarea 内容(错误的放到最上面)
      this.form.setFieldsValue({ phone_number: errorText + uniqValidPhoneNumbers.join('\n') })
    }
  }
}
</script>
<style lang="less" scoped>
.title {
  margin-bottom: 20px;

  .phone-number-count {
    text-align: right;
  }
}
</style>
